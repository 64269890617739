//
// tasks.scss
//

.board {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
}

.tasks {
    display: inline-block;
    width: 22rem;
    padding: 0 1rem 1rem 1rem;
    border: 1px solid var(--#{$prefix}border-color);
    vertical-align: top;
    margin-bottom: $spacer;
    @include border-radius($card-border-radius);

    &.tasks:not(:last-child) {
        margin-right: 1.25rem;
    }

    .card {
        white-space: normal;
        margin-top: 1rem;
    }

    .task-header {
        padding-top: 1rem;
    }
}

.task-list-items {
    min-height: 100px;
    position: relative;

    &:before {
        content: "No Tasks";
        position: absolute;
        line-height: 110px;
        width: 100%;
        text-align: center;
        font-weight: 600;
    }
}

// Task add modal
.task-modal-content {
    .form-control-light {
        background-color: var(--#{$prefix}light) !important;
        border-color: var(--#{$prefix}border-color) !important;
    }
}

// task - gantt
.gantt-task-details {
    min-width: 220px;
}
