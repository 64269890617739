//
// formm-wizard.scss
//

.form-wizard-header {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    background-color: var(--#{$prefix}tertiary-bg);
    border-top: 1px solid var(--#{$prefix}body-bg);
    border-bottom: 1px solid var(--#{$prefix}body-bg);
}
